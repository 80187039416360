<template>
    <div>
        <el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
            <el-form-item label="分润时间" label-width="120px">
                <el-date-picker
                    v-model="createDateRange"
                    type="daterange"
                    range-separator="-"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="代理商编号" label-width="120px">
                <el-input v-model="queryParams.sn"
                          clearable
                          placeholder="请输入代理商编号"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="分润账户" label-width="120px">
                <el-select v-model="queryParams.transStatus" placeholder="请选择活动类型" size="small" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="业务说明" label-width="120px">
                <el-select v-model="queryParams.transStatus" placeholder="请选择活动类型" size="small" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注" label-width="120px">
                <el-input v-model="queryParams.sn"
                          clearable
                          placeholder="请输入备注"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'AGENT_ACCOUNT_DETAIL_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery" v-permission="'AGENT_ACCOUNT_DETAIL_QUERY'">
                    重置
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {TradeApi} from '@/api';
    import { mapState } from 'vuex'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            },
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
            }
        },
        computed:{
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出活动明细",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await TradeApi.transOrder.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                let [beginTransTime, endTransTime] = this.createDateRange;
                this.queryParams.beginTransTime = beginTransTime && (beginTransTime + ' 00:00:00');
                this.queryParams.endTransTime = endTransTime && (endTransTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
